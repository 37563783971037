import React, { useState, useEffect } from "react"
import StoryblokService from '../../utils/storyblok-service'
import Layout from "../../sb-components/layout"
import Page from '../../sb-components/page'

const PreviewPage = (props) => {
  const [previewPage, setPreviewPage] = useState({});

  useEffect(() => {
    (async () => {
      setPreviewPage(await getInitialStory() || {});
      setTimeout(() => StoryblokService.initEditor(), 200);
    })();
  }, []);

  const getInitialStory = async () => {
    StoryblokService.setQuery(props.location.search);
    const { data: { story } } = await StoryblokService.get(`cdn/stories/${props.location.pathname}`);
    return story;
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Layout className={`page page-preview page-${previewPage?.slug}`}>
      <Page blok={{ ...previewPage?.content, title: previewPage?.content || "title" }} />
    </Layout>
  );
};

export default PreviewPage;
